var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    attrs: {
      "id": "sharepoint"
    }
  }, [_c('SpV2', {
    attrs: {
      "consenseNr": _vm.consenseNr
    }
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };