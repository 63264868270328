import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.string.includes.js";
import SpV2 from "@/components/SpV2";
export default {
  name: "Sharepoint",
  components: {
    SpV2: SpV2
  },
  data: function data() {
    return {
      aktonly: "",
      consenseNr: ""
    };
  },
  watch: {
    $route: {
      deep: true,
      immediate: true,
      handler: function handler(nv) {
        if (nv.path.includes("sharepoint") && this.$route.params.id.length >= 9) {
          var id = this.$route.params.id;
          this.consenseNr = id.substring(0, 10);
        }
      }
    }
  }
};